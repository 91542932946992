import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import HowText from '../component/HowText/HowText'
import MenBookAppointment from '../component/MenBookAppointment/MenBookAppointment'
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import { Helmet } from 'react-helmet'

function ForMen() {
  return (
    <>
    <Helmet>
      <title>Elev8te Wave Therapy For Men | How It Works For Men</title>
      <meta name='description' content='How Elev8te Wave Therapy Works For Men? Are you experiencing bladder leakage when you sneeze or exercise? Are you experiencing erectile dysfunction?' />
      <link rel="canonical" href="https://venushealthmedical.com/how-it-works-for-men/" />
      <meta name="keywords" content="Erectile Dysfunction Treatment Toronto"></meta>
    </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="Elev8te Men’s Therapy "
        nextLineSpanHeading=""
        Paragraph="Your Trusted Partner in Men's Health. We understand the challenges men face with urinary incontinence and erectile dysfunction. Our mission is a healthier, more confident you!"
        btnText="BOOK AN APPOINTMENT"
        btnRefJnap={true}
        bgImg="/images/creatives/men-hero-bg.jpg"
        btn={true}
        btnRef="contact-toronto-clinic"
      />

<div className='left-gradient'>

      <TextContent
         heading= "Treat Urinary Incontinence"
         subHeading=""
         postSubHeading="& Erectile Dysfunction"
         content={"Are you experiencing bladder leakage when you sneeze or exercise? Do you feel strong urges to urinate and feel like you won’t make it to the bathroom on time? Are you experiencing erectile dysfunction? These are all signs that you have an issue with your pelvic floor. \n When you have an issue with bladder leakage or other issues related to your pelvic floor, it can affect every aspect of your life. Some people even avoid certain activities for fear of embarrassment. This isn’t something you should simply accept, however. Get in touch with an Elev8te consultant for a free consultation."}
         btn={true}
         btnText="Free Assesment"
         btnRefTel={true}
         btnRef="/"
         shiftRow={true}
         img="/images/creatives/men-text-1.png"
      />

        <HowText
            heading='How Does It Work?'
            content={"At Elev8te Wave Therapy we can strengthen your pelvic floor muscles to treat a number of conditions, such as bladder incontinence and erectile dysfunction. \n While many men have heard of Kegel exercises, they may not realize they are effective for men as well as women. Elev8te Wave Therapy uses a “Kegel throne” that will work to strengthen your pelvic floor muscles with electromagnetic wave energy. The chair will produce the equivalent of 11,000 perfectly performed Kegels in less than half an hour, which is far more than you’d be able to perform on your own. \n Treatment is comfortable and pain-free. You can remain fully clothed during your treatment session and even read or watch TV. All it takes it one session to begin feeling the results."}
        />
</div>
<div className='right-gradient'>

      <TextContent
         heading= "Pelvic Wall Dysfunction In Men"
         subHeading=""
         content={"Many men are completely unaware of the pelvic floor until they experience a related issue. The pelvic floor is a muscle hammock that plays a role in bladder, bowel, and sexual functions. \n Bladder incontinence in men can take several forms: \n 1. Stress Incontinence: Activities such as lifting, laughing, sneezing or coughing put stress on your bladder, causing urine to leak. \n 2. Urge Incontinence: You feel a strong urge to urinate and have difficulty making it to the bathroom in time. \n 3. Overflow Incontinence: Your bladder doesn’t completely empty, leading to urinary leakage afterwards. \n Pelvic floor issues can affect men in a variety of ways. Thankfully, we offer advanced and effective treatment at Elev8te."}
         btn={true}
         btnText="Free Assesment"
         btnRefTel={true}
         shiftRow={false}
         img="/images/creatives/men-text-2.png"
         reviewContent={false}
      />

      <MenBookAppointment/>

      </div>
      <div className='bottom-gradient'>

      <VideoContent
            heading="Regain your confidence!"
            subHeading="Elev8te Men’s Therapy "
            content="At Elev8te, we understand that every individual is unique. Our tailored treatment plans address your specific needs and conditions. Experience drastic positive changes in your confidence and lifestyle after completing our program. Everyone deserves the freedom to live life without the limitations of pelvic health concerns!"
            btn={true}
            shiftRow={true}
            btnText="Free Assesment"
            btnRefTel={true}
            btnRef="/"
            img="/images/creatives/video-thumbnail-image-for-men.webp"
            reviewContent={false}
            videoSrc={"https://www.youtube.com/embed/bgbZhdmX51w?si=3399p6IkVzHPWY8O&autoplay=1"}
        />
      <div className='' style={{height:"40px"}}></div>
      <Testimonials
        mensData={true}
      />
      <Footer/>
      </div>
    </>
  )
}

export default ForMen