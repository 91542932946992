import React, { useState } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import './Popup.css';

function Popup({ closePopup ,modalHeading="Book an Appointment"}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [problem, setProblem] = useState('Bladder Leakage');
  const [location, setLocation] = useState('Toronto Clinic');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post('/send-contact-form-popup', {
        email: email,
        name: name,
        problem: problem,
        message: message,
        location:location,
        phoneNum: phone,
        formType: 'Message generated through Contact form',
      })
      .then((response) => {
        if (response.data.msg === 'success') {
          setError(false);
          setSuccess('Message sent Successfully!');
          setLoading(false);
          window.open('/thank-you', '_blank'); // Open thank you page in a new tab
          setEmail('');
          closePopup(); // Close the popup after successful submission
        } else if (response.data.msg === 'fail') {
          setSuccess(false);
          setError('Failed to send message, try again by reloading the page.');
          setLoading(false);
          setEmail('');
        }
      });
  };

  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    const maxLength = 25;

    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };

  return (
    <div className='popup'>
      <div className='popup-inner'>
        <button className='close-btn' onClick={closePopup} style={{color:"#f06e65",fontSize:"24px"}}>
          &times;
        </button>
        <h2 style={{color:"white"}} className='mt-2'>{modalHeading}</h2>
        <form className='form mt-2' onSubmit={formSubmitHandler}>
          <input
            type='text'
            value={name}
            required
            maxLength='36'
            onChange={handleNameChange}
            placeholder='Your Full Name'
            className='input-field'
          />
          <input
            type='email'
            value={email}
            required
            maxLength='66'
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Your Email Address'
            className='input-field'
          />
          <input
            type='tel'
            value={phone}
            maxLength='25'
            required
            onChange={handlePhoneChange}
            placeholder='Phone Number'
            className='input-field'
          />
          <select
            placeholder='Urinary incontinence / Bladder Leakage'
            className='input-field'
            required
            value={problem}
            onChange={(e) => {
              setProblem(e.target.value);
            }}
          >
            <option value='Bladder Leakage' style={{color:"black"}}>Bladder Leakage</option>
            <option value='Pelvic Prolapse' style={{color:"black"}}>Pelvic Prolapse</option>
            <option value='Sexual Health' style={{color:"black"}}>Sexual Health</option>
          </select>
          <input
            placeholder='Location'
            className='input-field'
            required
            value={location}
            onChange={(e) => {
              // setLocation(e.target.value);
            }}
          />
            {/* <option value='Milton - Mex Physio' style={{color:"black"}}>Milton - Mex Physio</option> */}
            {/* <option value='North York - True Life Wellness' style={{color:"black"}}>North York - True Life Wellness</option> */}
         
          <textarea
            className='input-field'
            value={message}
            maxLength='350'
            required
            onChange={(e) => setMessage(e.target.value)}
            rows={4}
            placeholder='Write Message'
            style={{resize:"none"}}
          ></textarea>
          <button className='service-form-btn mt-2' type='submit'>
            Submit
            {loading ? (
              <Spinner
                animation='border'
                role='status'
                style={{ color: 'white', width: '15px', height: '15px', marginLeft: '10px' }}
              />
            ) : (
              success ? (
                <div>
                  {setLoading(false)}
                  {alert(success)}
                  {setSuccess(false)}
                </div>
              ) : (
                error && (
                  <div>
                    {setLoading(false)}
                    {alert(error)}
                    {setError(false)}
                  </div>
                )
              )
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Popup;
