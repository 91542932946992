import React, { useState } from "react";
import "./Footer.css"
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap"
import axios from "axios"

function Footer({
  newsletterSection = true
}) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-newsletter", {
        email: email,
        formType: "Newsletter",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Newsletter Subscribed Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <>
      <div className="form">
        {newsletterSection &&
          <div className="container-fluid">
            <div className="form-container w-75 m-auto px-2">
              <div className="text-container text-center">
                <p className="sub-heading" style={{ color: "white" }}>stay up to date</p>
                <h3 className="body-heading" style={{ color: "white" }}>Join Our newsletter</h3>
              </div>

              <form className='inp-container d-md-flex d-none align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                <input
                  required
                  type="email"
                  value={email}
                  maxLength="36"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Your Email Address' />
                <button className='inp-btn' type="">SIGN UP
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                    />
                  ) : (
                    success ? (
                      <div >
                        {setLoading(false)}
                        {alert(success)}
                        {setSuccess(false)}
                      </div>
                    ) : (
                      error && (
                        <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                        </div>
                      )
                    )
                  )}
                </button>
              </form>
              <div className='inp-container-resp d-md-none d-flex flex-column align-items-center justify-content-between'>
                <input type='text' placeholder='Your Email Address' />
                <button className='inp-btn' type="submit">SIGN UP
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#D77F18", margin: "auto" }}
                    />
                  ) : (
                    success ? (
                      <div >
                        {setLoading(false)}
                        {alert(success)}
                        {setSuccess(false)}
                      </div>
                    ) : (
                      error && (
                        <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                        </div>
                      )
                    )
                  )}
                </button>
              </div>

            </div>
          </div>
        }
      </div>
      <div className={`footer-container ${!newsletterSection && "pt-0"}`}>

        {/* newsletter Upper section */}
        <div className="upper-newsletter-container container-fluid mx-auto" style={{ width: "90%" }}>

          <div className="row d-flex align-items-center gy-5 pt-5">
            <div className="col-lg-4 col-md-6 col-12 mt-md-0 mt-2">
              <div className="text-container">
                <div className="newsletter-logo img-fluid mb-3">
                  <img src="/images/icons/nav-logo.png" alt="logo" className="img-fluid" onClick={() => { navigate("/") }} style={{ cursor: "pointer" }} />
                </div>
                <div className="text-content">
                  <p>Your path to renewed confidence and wellness starts at Venus Health!</p>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-md-0 mt-2 mb-2">
              <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-md-evenly justify-content-center">
                <div className="text-md-start text-center d-flex flex-column gap-3">
                  <NavLink to={"/"} className="anchor" exact activeClassName="active">
                    Home
                  </NavLink>
                  <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                    About
                  </NavLink>
                  {/* <NavLink to={"/physiotherapy"} className="anchor" exact activeClassName="active">
                                Physiotherapy
                            </NavLink> */}
                  <NavLink to={"/blog"} className="anchor" exact activeClassName="active">
                    Blogs
                  </NavLink>
                  <NavLink to={"/benefits"} className="anchor" exact activeClassName="active">
                    Benefits
                  </NavLink>
                </div>
                <div className="text-md-start text-center d-flex flex-column gap-3">
                  <NavLink to={"/services"} className="anchor" exact activeClassName="active">
                    Services
                  </NavLink>
                  <NavLink to={"/contact-toronto-clinic"} className="anchor" exact activeClassName="active">
                    Contact Us
                  </NavLink>
                  <NavLink to={"/privacy-policy"} className="anchor" exact activeClassName="active">
                    Privacy Policy
                  </NavLink>
                  {/* <p className="anchor mb-0">Privacy Policy</p> */}
                  {/* <p className="anchor mb-0">Terms & Conditions</p>
                            <p className="anchor mb-0">Cookie Policy</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-md-0 mt-2">
              <div className="info-container">
                <div className="d-flex flex-column align-items-center justify-content-start gap-3">
                  {/* <div className="d-flex align-items-start gap-2  w-100">
                    <img src="/images/icons/location-icon.svg" alt="location icon" />
                    <div>
                      <p className="mb-0 loc-heading font-mulish">Mex Physio</p>
                      <a style={{ color: "white", textDecoration: "none" }} target="blank" href="https://www.google.com/maps/place/MEX+PHYSIO/@43.4805223,-79.8450679,17z/data=!3m2!4b1!5s0x882b65efbef21947:0xaa8cc5da6c6dce6d!4m6!3m5!1s0x882b659c383a45e9:0xcfd7b3740ca0b490!8m2!3d43.4805184!4d-79.842493!16s%2Fg%2F11fn4k43wp?hl=en&entry=ttu">
                        <p className="mb-0 font-mulish info-content">1594 Leger Wy Unit1, Milton, ON L9E 0B9</p>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-start gap-2 w-100">
                    <img src="/images/icons/phone-icon.svg" alt="phone icon" />
                    <div>
                      <a href="tel:(905) 636-6121" className="font-mulish info-content" style={{ color: "white", textDecoration: "none", cursor: "pointer" }}>(905) 636-6121</a>
                    </div>
                  </div> */}
                  <div className="d-flex align-items-start gap-2 w-100">
                    <img src="/images/icons/location-icon.svg" alt="location icon" />
                    <div>
                      <p className="mb-0 loc-heading font-mulish">Toronto Clinic</p>
                      <a style={{ color: "white", textDecoration: "none" }} target="blank" href="https://www.google.com/maps/place/2930+Islington+Ave+%232,+North+York,+ON+M9L+2K5,+Canada/@43.756613,-79.569775,8z/data=!4m6!3m5!1s0x882b3aa362104a29:0x49ed8bc4d97e603!8m2!3d43.756613!4d-79.5697746!16s%2Fg%2F11qqn6p_lj?hl=en&entry=ttu">
                        <p className="mb-0 font-mulish info-content">55 Avenue Road, East Tower, Suite 2000 Toronto ON M4R 3L2</p>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex align-items-start gap-2 w-100">
                    <img src="/images/icons/phone-icon.svg" alt="phone icon" />
                    <div>
                      <a href="tel:416-849-5555" className="font-mulish info-content" style={{ color: "white", textDecoration: "none", cursor: "pointer" }}>(416) 849-5555</a>
                    </div>
                  </div>
                  <div className="d-flex align-items-start gap-2 w-100">
                    <img src="/images/icons/mail-icon.png" alt="mail icon" />
                    <div>
                      <a href="mailto:info@thetorontoclinic.com" className="font-mulish info-content" style={{ color: "white", textDecoration: "none", cursor: "pointer" }}>info@thetorontoclinic.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>

        {/* div for border lining */}
        <div className="my-5"></div>

        {/* Lower sections */}

        <div className="lower-section text-center py-2">
          <div className="lower-container container flex-column gap-3 flex-md-row justify-content-md-between justify-content-center">
            <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            <p className="mb-0">© 2025 Venus Wave Therapy, All Rights Reserved.</p>
            <div className="social-link">
              <a href="https://www.facebook.com/venushealthmedical" target="_blank" rel="noopener noreferrer"
              ><img src="/images/icons/fb-icon.svg" className="social-icon" alt="facebook icon" /></a>
              <a href="https://www.instagram.com/venushealthmedical/" target="_blank"><img src="/images/icons/insta-icon.svg" className="social-icon" alt="instagram icon" /></a>
              <a href="https://twitter.com/venushealthmed" target="_blank" rel="oopener noreferrer"><img src="/images/icons/twitter-icon.svg" className="social-icon" alt="twitter icon" /></a>
              <a href="https://www.linkedin.com/company/venus-health-medical/?originalSubdomain=ca" target="_blank" rel="oopener noreferrer"><img src="/images/icons/linked-icon.svg" className="social-icon" alt="linkedln icon" /></a>
              
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer