import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from "../component/Footer/Footer"
import ContactForm from '../component/ContactForm/ContactForm'
import FaqSection from "../component/FaqSection/FaqSection"
import Map from "../component/Map/Map"
import { Helmet } from 'react-helmet'

const faqData = [
    {
        title:"WHO IS THE RIGHT CANDIDATE FOR TREATMENT AT VENUS WAVE THERAPY?",
        content:"Bladder leakage is more common in women which makes them the right candidates for treatment at Venus Wave Therapy. Also, women who have the need to strengthen their pelvic floor and improve their sexual function."
    },
    {
        title:"HOW LONG DOES THE TREATMENT LAST?",
        content:"Each treatment lasts 28 minutes in duration."
    },
    {
        title:"DO I NEED TO REMOVE MY CLOTHES DURING MY SESSION?",
        content:"There is no need to remove your clothing. You simply sit on the comfortable chair and have your treatment session."
    },
    {
        title:"IS THERE ANY PHYSICAL CONTACT REQUIRED?",
        content:"No.  There is no physical contact required with anyone during your treatment at Venus Wave Therapy. All you need to do is sit on the treatment chair and feel the electromagnetic energy to your pelvic floor muscles. During that time you can read your favorite book or watch your favorite tel"
    },
    {
        title:"IS THE TREATMENT PAINFUL?",
        content:"The treatment does not cause any pain. You will feel tingling and contractions of your pelvic floor muscles and after you are done with the treatment, you can go back to your normal daily activities."
    },
    {
        title:"ARE THERE ANY SIDE EFFECTS?",
        content:"There are no negative side effects associated with our treatment."
    },
    {
        title:" HOW FAST WILL I EXPERIENCE RESULTS?",
        content:"You will begin to notice an improvement after the first session, and with each subsequent appointment you will continue to improve."
    },
    {
        title:" WHO IS NOT A GOOD CANDIDATE FOR TREATMENT AT VENUS WAVE THERAPY?",
        content:"It is not recommended for pregnant women, women who have had a metal hip replacement, have a pacemaker, or have a copper IUD in place. It is recommended for pregnant women to get the treatment after the pregnancy ends."
    },
    {
        title:" HOW SOON AFTER PREGNANCY SHOULD I WAIT BEFORE TREATMENT AT VENUS WAVE THERAPY?",
        content:"Once your doctor tells you that you are ready to start exercising, you can schedule your appointment with us."
    },
    {
        title:"IS THE TREATMENT SAFE?",
        content:"The treatment is completely safe and approved by both the FDA and Health Canada."
    },
    {
        title:" WHAT SHOULD I WEAR DURING MY SESSIONS?",
        content:"Please wear comfortable- fitting clothes so you can feel relaxed during the treatment."
    },
    {
        title:" CAN MEN RECEIVE TREATMENT AT VENUS WAVE THERAPY?",
        content:"Yes. Men can (and do) benefit from this therapy. Men also struggle with bladder leakage issues and the treatment will benefit them as well."
    },
    {
        title:"IS THIS COVERED BY OHIP?",
        content:"No, it is not."
    },
    {
        title:"IS THIS COVERED BY EXTENDED HEALTH BENEFITS?",
        content:"Our team will provide you with the necessary information to support your insurance claim. Note that treatment is not typically covered under standard health benefits."
    },
    {
        title:"CAN I CLAIM THIS ON MY TAX RETURN?",
        content:"Yes, our team will advise you on how to claim the treatment costs as a medical expense for your income tax return."
    },
    {
        title:"CAN THIS TREATMENT HELP WITH ENDOMETRIOSIS?",
        content:"Women with endometriosis can get improvements with their condition, with pain decreasing over time."
    },
    {
        title:"CAN'T FIND THE ANSWER TO YOUR QUESTION?",
        content:"Yes, our team will advise you on how to claim the treatment costs as a medical expense for your income tax return."
    },
    {
        title:"WHAT IS IT SUPPOSED TO FEEL LIKE?",
        content:"Our chair feels like comfortable electric impulses entering into the pelvic floor. Think of a TENS machine going for over 11 thousand pulses in a spot that is built to handle it."
    },
    {
        title:"WHAT’S THE REASON FOR THE BREAK IN BETWEEN PROTOCOLS?",
        content:"The reason for the break in between protocols 1 and 2 is to rest the pelvic floor and help rejuvenate the area, similar to how you'd let any muscle rest after a gym session. Often, there’s continuous improvement during the rest period!"
    },
    {
        title:"WHEN CAN/SHOULD I COME BACK FOR MAINTENANCE?",
        content:"We advise maintenance treatments along with yoga or pilates classes that focus on the pelvic floor (which most do). We also recommend that people do Kegel’s outside of the clinic. The work you put into your body to help improve your pelvic floor function (body mechanics, diet, core exercises) is extremely important. The better you take care of pelvic health outside of Venus, the better off you will be."
    },
    {
        title:"WHEN CAN/SHOULD I COME BACK FOR MAINTENANCE?",
        content:"We advise maintenance treatments along with yoga or pilates classes that focus on the pelvic floor (which most do). We also recommend that people do Kegel’s outside of the clinic. The work you put into your body to help improve your pelvic floor function (body mechanics, diet, core exercises) is extremely important. The better you take care of pelvic health outside of Venus, the better off you will be."
    },
    {
        title:"WHY SHOULDN’T I DRINK COFFEE?",
        content:"We advise against coffee, alongside decaf, because of the caffeine. Caffeine is a powerful diuretic and can be very irritating to the bladder when you're incontinent. You may notice this with a bladder diary, which will help track what you were doing, eating, emotions, accidents, time of day, and nighttime bathroom trips. Alongside caffeine, it is in your best interest to identify other triggers that cause bladder accidents using the diary."
    },
]

function ContactMilton() {
    return (
        <>
        <Helmet>
            <title>Venus Wave Therapy Clinic Ontario | Contact Us | Pelvic Floor Strengthening</title>
            <meta name='description' content='Contact Venus Wave Therapy clinic Ontario today to learn more about non-invasive Pelvic Floor Treatment in Toronto, Ontario.' />
            <link rel="canonical" href="https://venushealthmedical.com/contact-toronto-clinic/" />
            <meta name="keywords" content="Contact Us"></meta>
        </Helmet>
          {/* <HeroBanner 
            subHeading="VENUS WAVE THERAPY IS WOMEN'S HEALTH"
            Heading="Venus Wave Therapy Clinic Ontario"
            nextLineSpanHeading=""
            Paragraph="We are committed to providing women who experience urinary incontinence and/or sexual dysfunction with the safest, non-invasive, cutting edge, and long-lasting treatment. Our team is dedicated to providing exceptional, discreet care in a warm, caring and comfortable environment. We are trained to answer all of your questions and concerns during a thorough, no-charge consultation."
            btn={false}
            btnText=""
            bgImg="/images/creatives/contact-hero-milton-bg-new.jpg"
            appointmentBtn={false}
          /> */}
          <HeroBanner 
            subHeading=""
            Heading="Pelvic Floor Clinics Toronto and Milton"
            nextLineSpanHeading=""
            Paragraph=""
            btn={false}
            btnText=""
            bgImg="/images/creatives/contact-hero-milton-bg.webp"
            appointmentBtn={false}
          />
          <div style={{height:'20px'}}></div>
    <div className='left-gradient'>
          <ContactForm
            Heading="Say "
            spanHeading="Hello!"
            Paragraph="Send us a message, we will get back to you via email as soon as possible, thank you for trusting and contacting us."
            btn={false}
            btnText=""
            socialLinks={true}
            formApi={"/send-contact-form-milton"}
            loc="mex"
          />
    </div>
    <div className='right-gradient'>  
    <Map
        loc='mex'
    />  
    <FaqSection
         heading="Frequently Asked Questions"
         subHeading="Still have Questions?"
         showImg={true}
         faqData={faqData}
    />
         
    </div>
    <div className='bottom-gradient'>
         
    
        <div style={{height:"40px"}}></div>

          <Footer
            newsletterSection={false}
          />
    </div>
        </>
      )
}

export default ContactMilton