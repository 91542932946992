import React from 'react'
import "./Map.css"

function Map({
  loc="mex"
}) {
  return (
    <div className='map-container standard-margin-space pt-5'>
        <div className=' d-flex flex-column flex-lg-row justify-content-between align-items-center gap-lg-4 gap-2 py-3'>
          {
            loc==="mex"
            ?
           <div className='first-map w-lg-50 w-100' >
                <iframe 
                // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d370452.9626360536!2d-79.877636!3d43.49891900000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b6f8179f765ff%3A0x74fe55e796570817!2s420%20Bronte%20St.%20S%20%23105b%2C%20Milton%2C%20ON%20L9T%200H9%2C%20Canada!5e0!3m2!1sen!2sus!4v1704534322147!5m2!1sen!2sus" 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.892674986068!2d-79.39737997382225!3d43.67120187110121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34a4efdd78a3%3A0x2b02a80d3936ee8!2sThe%20Toronto%20Clinic!5e0!3m2!1sen!2sus!4v1738779873281!5m2!1sen!2sus" 
                  style={{border:"0"}} 
                  className='iframe-map'
                  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                  </iframe>
            </div>
            :
            <div className='second-map w-lg-50 w-100' >
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d368868.16118795314!2d-79.569775!3d43.756613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3aa362104a29%3A0x49ed8bc4d97e603!2s2930%20Islington%20Ave%20%232%2C%20North%20York%2C%20ON%20M9L%202K5%2C%20Canada!5e0!3m2!1sen!2sus!4v1704534777037!5m2!1sen!2sus"
                style={{border:"0"}} 
                className='iframe-map'
               allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          }
        </div>
    </div>
  )
}

export default Map