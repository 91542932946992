import React,{useState} from 'react'
import "./ContactForm.css"
import axios from "axios"
import {Spinner} from "react-bootstrap"
import { useNavigate } from 'react-router-dom';

function ContactForm(
    {
        Heading,
        spanHeading,
        Paragraph,
        btnText,
        btn=false,
        socialLinks=true,
        formApi=null,
        loc="mex"
    }
) {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [problem, setProblem] = useState("Bladder Leakage");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${formApi ? formApi : "/send-contact-form-main"}`, {
        email: email,
        name:name,
        problem:problem,
        message:message,
        phoneNum:phone,
        formType: "Message genrated through Contact form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
          window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };



  return (
    <div className='contact-form-container standard-margin-space standard-padding-space'>
        <div className='container-fluid'>
            <div className='row px-2 px-md-5 gy-4'>
                <div className='col-lg-6 text-left'>
                    <div className='left-text-container'>
                        <h2 className="hero-section-heading " style={{fontSize:"44px",fontWeight:"600",color:"#303030",textTransform:"capitalize"}}>
                            {Heading}
                            <span className="heading-color-change" style={{fontSize:"44px",fontWeight:"600",color:"#85339B",textTransform:"capitalize"}}>{spanHeading}</span>
                        </h2>
                        {/* <p className="body-paragraph mb-4 mt-3 w-75">{Paragraph}</p> */}
                        {btn && <button className="hero-btn mt-4">{btnText}</button>}
                        {socialLinks && (
                        <div className="social-links py-2 d-flex flex-column align-items-start justify-content-start  gap-4">

                            <div className="first-row d-flex flex-column flex-xxl-row gap-4">
                                <div className='first-row-phone d-flex gap-lg-4 gap-4 flex-lg-row flex-column '>
                                    
                                   
                                </div>
                           
                            </div>

                            {
                                loc === "mex"
                                ?
                                <>
                                <div className="link-container d-flex align-items-center gap-1">
                                    <img src="/images/icons/social-3.svg" className='social-icons' alt='social-icon'/>
                                    <div>
                                        <p className="m-0" style={{fontSize:"18px",fontWeight:"500"}}>Toronto Clinic</p>
                                        <p className="m-0" style={{fontSize:"16px",fontWeight:"400",color:" #8f8f8f"}}>55 Avenue Road, East Tower, Suite 2000 Toronto ON M4R 3L2</p>
                                    </div>
                                </div>
                                <div className="link-container d-flex align-items-center gap-1">
                                    <img src="/images/icons/social-2.svg" className='social-icons' alt='social-icon'/>
                                    <a 
                                        href='mailto:info@thetorontoclinic.com'
                                        style={{userSelect:"all" , color:'#515151', textDecoration:"none"}}
                                    >
                                        <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>info@thetorontoclinic.com</p>
                                    </a>
                                </div>
                                <div className="link-container d-flex align-items-center gap-1">
                                    <img src="/images/icons/social-1.svg" className='social-icons' alt='social-icon'/>
                                    <a 
                                        href='tel:416-849-5555'
                                        style={{userSelect:"all" , color:'#515151', textDecoration:"none"}}
                                    >
                                        <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>(416) 849-5555</p>
                                    </a>
                                </div> 
                                </>
                                :
                                <>
                                <div className="link-container d-flex align-items-center gap-1">
                                    <img src="/images/icons/social-3.svg" className='social-icons' alt='social-icon'/>
                                    <div>
                                        <p className="m-0" style={{fontSize:"18px",fontWeight:"500"}}> Toronto Clinic</p>
                                        <p className="m-0" style={{fontSize:"16px",fontWeight:"400",color:" #8f8f8f"}}>55 Avenue Road, East Tower, Suite 2000 Toronto ON M4R 3L2</p>
                                    </div>
                                </div>
                                <div className="link-container d-flex align-items-center gap-1">
                                    <img src="/images/icons/social-2.svg" className='social-icons' alt='social-icon'/>
                                    <a 
                                        href='mailto:info@thetorontoclinic.com'
                                        style={{userSelect:"all" , color:'#515151', textDecoration:"none"}}
                                    >
                                        <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>info@thetorontoclinic.com</p>
                                    </a>
                                </div>
                                <div className="link-container d-flex align-items-center gap-1">
                                    <img src="/images/icons/social-1.svg" className='social-icons' alt='social-icon'/>
                                    <a 
                                        href='tel:416-849-5555'
                                        style={{userSelect:"all" , color:'#515151', textDecoration:"none"}}
                                    >
                                        <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>(416) 849-5555</p>
                                    </a>
                                </div>
                                </>
                            }
                            
                            
                            {/* <div className="link-container d-flex align-items-center gap-1">
                                <img src="/images/icons/social-2.svg" className='social-icons' alt='social-icon'/>
                                <a 
                                    href='mailto:info@venushealthmedical.com'
                                    style={{userSelect:"all" , color:'#515151', textDecoration:"none"}}
                                >
                                <p className="m-0" style={{fontSize:"16px",fontWeight:"400"}}>info@venushealthmedical.com</p>
                                </a>
                            </div> */}
                        </div>
                        )}
                    </div>
                </div>
                <div className='col-lg-6'>
                    <form className='form d-flex flex-column gap-3' onSubmit={formSubmitHandler}>
                    <div className="first-row d-flex flex-column flex-xl-row gap-xl-4 gap-3">
                        <input 
                         type="text" 
                         value={name}
                         required
                         maxLength="36"
                         onChange={handleNameChange}
                         placeholder="Your Full Name"  
                        className='w-xl-50 w-100 input-field' 
                        />
                        <input 
                        type="email" 
                        value={email}
                        required
                        maxLength="66"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email Address" 
                        className='w-xl-50 w-100 input-field' />
                    </div>
                        <input 
                         type="tel" 
                         value={phone}
                         maxLength="25"
                         required
                         onChange={handlePhoneChange}
                        placeholder="Phone Number" 
                        className='input-field' />
                        <select 
                            placeholder="Urinary incontinence / Bladder Leakage"
                            className='input-field'
                            required
                            value={problem}
                            onChange={(e)=>{setProblem(e.target.value)}}
                        >
                            <option value="Bladder Leakage">Bladder Leakage</option>
                            <option value="Pelvic Prolapse">Pelvic Prolapse</option>
                            <option value="Sexual Health">Sexual Health</option>
                        </select>
                        <textarea 
                        className="input-field" 
                        value={message}
                        maxLength="350"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        rows={4} placeholder="Write Message"
                        ></textarea>
                        <button className="service-from-btn  mt-2" type='submit'>Submit
                        {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                  
                        </button>
                    </form>
                    </div>
                </div>  
            </div>
        </div>  

  )
}

export default ContactForm